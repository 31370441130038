import React, { useCallback, useMemo } from 'react'
import { IconButton } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

const AUTO_HIDE_DEFAULT_DURATION = 5000

/**
 * Opts can be found here:
 * https://iamhosseindhv.com/notistack/api
 */
export default function useNotifications(props) {
  const { closeButton = true } = props || {}
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const defaultOptions = useMemo(() => {
    const baseOptions = {
      autoHideDuration: AUTO_HIDE_DEFAULT_DURATION,
    }

    if (closeButton) {
      return {
        ...baseOptions,
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} size="large" data-testid="icon-btn">
            <Clear />
          </IconButton>
        ),
      }
    }
    return baseOptions
  }, [closeButton, closeSnackbar])

  const notify = useCallback((msg, opts = {}) => enqueueSnackbar(msg, opts), [enqueueSnackbar])
  const success = useCallback(
    (msg, opts = {}) => enqueueSnackbar(msg, { ...defaultOptions, ...opts, variant: 'success' }),
    [defaultOptions, enqueueSnackbar],
  )
  const info = useCallback(
    (msg, opts = {}) => enqueueSnackbar(msg, { ...defaultOptions, ...opts, variant: 'info' }),
    [defaultOptions, enqueueSnackbar],
  )
  const warning = useCallback(
    (msg, opts = {}) => enqueueSnackbar(msg, { ...defaultOptions, ...opts, variant: 'warning' }),
    [defaultOptions, enqueueSnackbar],
  )
  const error = useCallback(
    (msg, opts = {}) => enqueueSnackbar(msg, {
      ...defaultOptions,
      autoHideDuration: AUTO_HIDE_DEFAULT_DURATION,
      ...opts,
      variant: 'error',
    }),
    [defaultOptions, enqueueSnackbar],
  )

  const returnValue = useMemo(() => ({
    notify, success, info, warning, error,
  }), [error, info, notify, success, warning])

  return returnValue
}
