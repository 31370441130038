import React, { useState } from 'react'
import { FormHelperText, OutlinedInput } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Adornment from '../Adornment'
import InputLabel from '../InputLabel'

export const CommonControlDefaultProps = {
  disabled: false,
  error: false,
  fullWidth: false,
  gutterBottom: false,
  required: false,
}

const PREFIX = 'CommonInputDefaultProps'

const classes = {
  root: `${PREFIX}-root`,
  adornedStart: `${PREFIX}-adornedStart`,
  adornedEnd: `${PREFIX}-adornedEnd`,
  disabled: `${PREFIX}-disabled`,
  input: `${PREFIX}-input`,
  notchedOutline: `${PREFIX}-notchedOutline`,
  focused: `${PREFIX}-focused`,
  marginDense: `${PREFIX}-marginDense`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white,
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
      boxShadow: `0px 0px 0.3125rem ${theme.palette.primary.main}`,
    },
  },

  [`& .${classes.adornedStart}`]: {
    paddingLeft: theme.spacing(1),
  },

  [`& .${classes.adornedEnd}`]: {
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.disabled}`]: {
    backgroundColor: theme.palette.action.disabledBackground,
  },

  [`& .${classes.input}`]: {
    paddingTop: theme.spacing(1.3125),
    paddingBottom: theme.spacing(1.3125),
    '&-marginDense &': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    '&::placeholder': {
      color: theme.palette.action.disabled,
      fontWeight: 500,
      opacity: 1,
    },
    '&:-webkit-autofill': {
      '&, &:hover': {
        WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.mode === 'dark' ? theme.palette.common.black : theme.palette.common.white
          // eslint-disable-next-line indent
          } inset !important`,
        '&:disabled': {
          WebkitBoxShadow: `0 0 0px 1000px ${theme.palette.action.disabledBackground} inset !important`,
        },
      },
    },
    '&:-webkit-autofill:disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },

  [`& .${classes.notchedOutline}`]: {
    borderColor: theme.palette.action.disabled,
    transition: 'ease-in 300ms',
    transitionProperty: 'border, box-shadow',
  },
}))

export const CommonInputDefaultProps = {
  ...CommonControlDefaultProps,
  autoFocus: false,
  large: false,
}

export function BaseInput({
  autoComplete,
  autoFocus,
  disabled,
  endAdornment,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  inputProps,
  InputProps,
  inputRef,
  label,
  labelRequired,
  large,
  max,
  min,
  maxRows,
  minRows,
  multiline,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  startAdornment,
  type,
  value,
  disabledEndAdornmentPointerEvents = true,
}) {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <Root>
      {label && (
        <InputLabel id={name} required={required || labelRequired} data-testid="input-label">
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        classes={classes}
        disabled={disabled}
        endAdornment={
          endAdornment && (
            <Adornment focused={isFocused} position="end" disablePointerEvents={disabledEndAdornmentPointerEvents}>
              {endAdornment}
            </Adornment>
          )
        }
        error={error}
        fullWidth={fullWidth}
        id={name}
        inputProps={{
          ...inputProps,
          max,
          min,
        }}
        inputRef={inputRef}
        margin={large ? 'none' : 'dense'}
        maxRows={maxRows}
        minRows={minRows}
        multiline={multiline}
        notched={false}
        name={name}
        onBlur={(event) => {
          setIsFocused(false)
          onBlur && onBlur(event)
        }}
        onChange={onChange}
        onFocus={(event) => {
          setIsFocused(true)
          onFocus && onFocus(event)
        }}
        placeholder={placeholder}
        required={required}
        startAdornment={
          startAdornment && (
            <Adornment focused={isFocused} position="start">
              {startAdornment}
            </Adornment>
          )
        }
        type={type}
        value={value}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...InputProps}
      />
      {(helperText || gutterBottom) && (
        <FormHelperText error={error} data-testid="helper-text">{helperText || (gutterBottom && ' ')}</FormHelperText>
      )}
    </Root>
  )
}

BaseInput.defaultProps = CommonInputDefaultProps

BaseInput.propTypes = {
  inputProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
  labelRequired: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  onBlur: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  endAdornment: PropTypes.node,
  large: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  startAdornment: PropTypes.node,
  disabledEndAdornmentPointerEvents: PropTypes.bool,
}
