import { Box, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useLocation } from 'react-router-dom'
import Loading from '../../components/Loading'
import Typography from '../../components/Typography'
import VerifyForm from '../../components/VerifyForm'
import { gotoDocs } from '../../util/Env'

const PREFIX = 'Verify'

const classes = {
  steps: `${PREFIX}-steps`,
  keyInfo: `${PREFIX}-keyInfo`,
}

const Root = styled('div')(() => ({
  [`& .${classes.steps}`]: {
    paddingBottom: '0.50rem',
  },

  [`& .${classes.keyInfo}`]: {
    fontSize: '0.85rem',
    wordBreak: 'break-all',
  },
}))

function Verify() {
  const state = useLocation().state

  if (!state) {
    return <Loading />
  }

  const isSkippable = !state.force_mfa && state.response.status === 'disabled'
  const helpLink = state.help_link

  return state.response.type === 'totp' ? (
    <Root>
      {state.response.status === 'disabled' && (
        <>
          <Box textAlign="center">
            <Typography variant="body1">
              <img data-testid="qr-code" alt="QR code" src={state.response.qr_code_image_data_url} height="220" data-test-hook="qr-code" />
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="body1" className={classes.steps}>
              1. Get a TOTP-compatible authenticator app from the
              {' '}
              <strong>App Store</strong>
              {' '}
              or
              {' '}
              <strong>Play Store</strong>
              .
            </Typography>
            <Typography variant="body1" className={classes.steps}>
              2. From within the app, select
              {' '}
              <strong>Set up account</strong>
              .
            </Typography>
            <Typography variant="body1" className={classes.steps}>
              3. Choose
              {' '}
              <strong>Scan barcode</strong>
              {' '}
              or enter the
              {' '}
              <strong>key</strong>
              {' '}
              manually.
            </Typography>
            <Typography variant="caption" className={classes.keyInfo} data-testid="qr-key">
              (Key:
              {' '}
              {state.response.shared_secret}
              )
            </Typography>
          </Box>
          <Box mt={2} mb={4}>
            <Divider />
          </Box>
        </>
      )}
      <VerifyForm
        type="totp"
        desc="Enter the 6-digit code you see in your authenticator app."
        placeholder="123456"
        isSkippable={isSkippable}
        autoFocus
        autoComplete="off"
        helpLink={helpLink}
        onClickHelpLink={(e) => gotoDocs({ e, link: helpLink })}
        hasDivider
        logoLink={state.logo_link}
      />
    </Root>
  ) : (
    <VerifyForm
      type="sms"
      desc={`Enter the verification code sent to your mobile device ending in ${state.response.phone_number_ending_in}.`}
      help="Message and data rates may apply"
      placeholder="123456"
      isSkippable={isSkippable}
      autoFocus
      autoComplete="off"
      helpLink={helpLink}
      onClickHelpLink={(e) => gotoDocs({ e, link: helpLink })}
      hasDivider
      logoLink={state.logo_link}
    />
  )
}

export default Verify
