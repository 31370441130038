import { Box } from '@mui/material'
import React from 'react'
import Link from '../components/Link'

export const ACCOUNT_LOCKED_MESSAGE = 'Your account has been locked due to security reasons. Manually unlock your account by resetting your password or by contacting support.'

export const GENERIC_ERROR_MESSAGE = 'An error occurred processing your request. Please try again or contact support.'

export const DEFAULT_ERROR_MESSAGE = 'An error has occurred. Please try again or contact support.'

export const VERIFICATION_CODE_FAILED_MESSAGE = 'An error occurred while validating your code. Please double check your code and try again or submit a new code.'

export const INVALID_PHONE_NUMBER_MESSAGE = 'Please double check your phone number.'

export const UNABLE_TO_VERIFY_NUMBER_MESSAGE = 'An error occurred while verifying your phone number. Please try again or contact support if the error persists.'

export const MESSAGE_DATA_RATES_MESSAGE = 'Message and data rates may apply.'

export const SKIP_STEP_ERROR_MESSAGE = 'An error occurred in attempting to skip this step. Please try again or contact support if the error persists.'

export const FORCE_PASSWORD_RESET_MESSAGE = (
  <>
    Your account needs a new password. Please click on the “Reset Password” button below to proceed.
    <Box component="p" mt={1} mb={0}>
      Contact
      {' '}
      <Link underline="always" centered={false} href="https://docs.edgecast.com/cdn/#Support_Services/Technical_Customer_Support.htm" newTab tabIndex={0}>
        technical customer support
      </Link>
      {' '}
      for assistance.
    </Box>
  </>
)

export const FORCE_PASSWORD_RESET_MESSAGE_NO_LINK = 'Your account needs a new password. Please click on the “Reset Password” button below to proceed.'

export const LOGIN_FIELDS_ERROR_MESSAGE = (isEmailOnlyView) => `Invalid username${isEmailOnlyView ? '' : ' or password'}`
