import React from 'react'
import { styled } from '@mui/material/styles'
import { ReactComponent as Icon } from '../../images/google.svg'

const PREFIX = 'GoogleIcon'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledIcon = styled(Icon)(() => ({
  [`&.${classes.root}`]: {
    height: 16,
    width: 16,
    '& path': {
      fill: 'currentColor',
    },
  },
}))

export default function GoogleIcon() {
  return <StyledIcon className={classes.root} data-testid="google-icon" />
}
