import React from 'react'
import PropTypes from 'prop-types'
import Link from '../Link'
import Typography from '../Typography'

import './index.scss'

const AssistLink = ({
  onClick, text, href, color = null,
}) => (
  <Typography variant="subtitle2" className="assistLink" color={color}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <Link onClick={onClick} tabIndex={0} href={href}>
      <label className="helpLink">{text}</label>
    </Link>
  </Typography>
)

AssistLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  color: PropTypes.string,
}

export default AssistLink
