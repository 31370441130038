import { PhonelinkLockOutlined, VerifiedUserOutlined } from '@mui/icons-material'
import { parseError } from './Helper'

export const PATHS = {
  user_signed_in: '/temp',
  user_verification: '/login',
  user_authentication: '/login',
  set_mfa: '/options',
  challenge_sms: '/challenge',
  verify_factor: '/verify',
}

// might be removed later on
export const OPTIONS = [
  {
    type: 'totp',
    icon: VerifiedUserOutlined,
    title: 'Authenticator App',
    details: 'Use a TOTP-compatible authenticator app to get verification codes.',
    color: 'success',
    level: 'Secure',
  },
  {
    type: 'sms',
    icon: PhonelinkLockOutlined,
    title: 'Text Message',
    details: 'Verification codes are sent by text message.',
    color: 'warn',
    level: 'Less Secure',
  },
]

export const gotoLink = ({
  e,
  navigate,
  data,
  notifications,
  isRoot,
  isAuthnTarget = false,
  link = '/',
  state,
  isExternalPage = false,
}) => {
  if (e) {
    e.preventDefault()
  }

  if (isAuthnTarget) {
    if (isRoot) {
      window.location.href = '/authn'
    } else {
      notifications.error(parseError(data), {
        onClose: () => {
          localStorage.clear()
          window.location.href = '/authn'
        },
      })
    }
  } else if (isExternalPage) {
    window.location.href = link
  } else {
    // `replace` instead of default `push,
    // since ideally shouldn't be able to access prev step
    navigate(link, { state, replace: true })
  }

  return null
}

export const gotoDocs = ({ e = null, link = '/' }) => {
  if (e) {
    e.preventDefault()
  }

  window.open(link, '_blank')

  return null
}

export const getLinkPath = (res) => {
  let redirectLink = '/'
  // eslint-disable-next-line camelcase
  let help_link

  if (res['@links'] && res['@links'].redirect_uri) {
    redirectLink = res['@links'].redirect_uri.href
  } else if (res.state) {
    redirectLink = PATHS[res.state]
  }

  if (res['@links'] && res['@links'].help) {
    // eslint-disable-next-line camelcase
    help_link = res['@links'].help.href
  }

  const isExternalPage = !!res['@links'] && !!res['@links'].redirect_uri && !!res['@links'].redirect_uri.href

  return { redirectLink, isExternalPage, help_link }
}
