import React from 'react'

import './index.scss'

export default function LoadingPage() {
  return (
    <div className="ball-loader">
      <div className="ball-loader-ball ball1" />
      <div className="ball-loader-ball ball2" />
      <div className="ball-loader-ball ball3" />
    </div>
  )
}
