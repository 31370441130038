import React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import Typography from '../Typography'
import Link from '../Link'

export default function SignUpLink({ link }) {
  return (
    <Box mt={4}>
      <Typography>
        Don&apos;t have an account?
        {' '}
        <Link href={link}>
          Create one!
        </Link>
      </Typography>
    </Box>
  )
}

SignUpLink.propTypes = {
  link: PropTypes.string.isRequired,
}
