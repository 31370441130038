import React from 'react'
import PropTypes from 'prop-types'
import TextField from '../TextField'

export default function FormikTextField({
  name,
  helperText,
  readOnly,
  validate,
  error,
  onChange,
  onBlur,
  value,
  ...otherProps
}) {
  return (
    <TextField
      error={error}
      helperText={helperText}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={readOnly}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  )
}

FormikTextField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  onBlur: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  endAdornment: PropTypes.node,
  large: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  startAdornment: PropTypes.node,
  /**
 * @ignore
 * Used only by the `AutoComplete` component
 */
  inputProps: PropTypes.shape({}),
  /**
   * @ignore
   * Used only by the `AutoComplete` component
   */
  InputProps: PropTypes.shape({}),
  /**
   * @ignore
   * Used only by the `DatePicker` component
   */
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
  /**
   * @ignore
   * Used only by the `Select` component
   */
  labelRequired: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  validate: PropTypes.func,
}
