/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { Box, alpha } from '@mui/material'
import { styled } from '@mui/material/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import logo from '../../images/edgio_edgecast_logo.png'
import Typography from '../Typography'

const PREFIX = 'Layout'

const classes = {
  container: `${PREFIX}-container`,
  noGradient: `${PREFIX}-noGradient`,
  mainLogo: `${PREFIX}-mainLogo`,
  errorWhite: `${PREFIX}-errorWhite`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    backgroundImage: `linear-gradient(180deg, ${alpha(theme.palette.common.white, 0)} 40%, ${alpha(
      theme.palette.secondary.main,
      0.1,
    )} 100%)`,
  },

  [`& .${classes.noGradient}`]: {
    backgroundImage: 'none',
  },

  [`& .${classes.mainLogo}`]: {
    height: '91px',
    pointerEvents: 'none',
  },

  [`& .${classes.errorWhite}`]: {
    color: theme.palette.warning.contrastText,
  },
}))

const Layout = ({ children, error, maxWidth = 450, noGradient = false, logoLink }) => (
  <StyledBox
    alignItems="center"
    className={cx(classes.container, { [classes.noGradient]: noGradient })}
    display="flex"
    flex={1}
    flexDirection="column"
    padding={4}
  >
    <Box
      component="img"
      mb={5}
      textAlign="center"
      src={logoLink || logo}
      className={classes.mainLogo}
      alt="Edgio"
    />
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      maxWidth={maxWidth}
      width="100%"
    >
      {error && (
        <Box bgcolor="error.main" borderRadius={1} p={1} mb={2}>
          <Typography className={classes.errorWhite} display="inline">
            {error}
          </Typography>
        </Box>
      )}
      {children}
    </Box>
    <Box mt={4} textAlign="center">
      <Typography variant="caption">
        © {new Date().getFullYear()} Edgio Inc. All rights reserved.
      </Typography>
    </Box>
  </StyledBox>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  maxWidth: PropTypes.number,
  noGradient: PropTypes.bool,
  logoLink: PropTypes.string,
}

export default Layout
