import React from 'react'
import { styled } from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import PropTypes from 'prop-types'

const PREFIX = 'index'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledInputAdornment = styled(InputAdornment)(({ theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.action.disabled,
    '&.focused': {
      color: theme.palette.action.active,
    },
  },
}))

export default function Adornment({
  children, focused = false, position, disablePointerEvents = false,
}) {
  return (
    <StyledInputAdornment
      classes={classes}
      className={focused ? 'focused' : ''}
      disablePointerEvents={disablePointerEvents}
      disableTypography
      position={position}
    >
      {children}
    </StyledInputAdornment>
  )
}

Adornment.propTypes = {
  children: PropTypes.node.isRequired,
  focused: PropTypes.bool,
  position: PropTypes.oneOf(['start', 'end']).isRequired,
  disablePointerEvents: PropTypes.bool,
}
