import PropTypes from 'prop-types'
import React from 'react'
import Button from '../Button'
import GitHubIcon from './GitHubIcon'
import GoogleIcon from './GoogleIcon'
import MicrosoftIcon from './MicrosoftIcon'

const iconMap = {
  github: <GitHubIcon />,
  google: <GoogleIcon />,
  microsoft: <MicrosoftIcon />,
}
export default function SocialButton({ type, href, label = 'Github' }) {
  const icon = iconMap[type]
  return (
    <Button fullWidth large startIcon={icon} color="dual" href={href} variant="social">
      {label}
    </Button>
  )
}

SocialButton.propTypes = {
  type: PropTypes.oneOf(['github', 'google', 'microsoft']).isRequired,
  href: PropTypes.string.isRequired,
  label: PropTypes.string,
}
