import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Divider } from '@mui/material'
import { green, orange } from '@mui/material/colors'
import { VerifiedUserOutlined } from '@mui/icons-material'
import useNotifications from '../../hooks/useNotifications'
import Typography from '../../components/Typography'
import Button from '../../components/Button'
import AssistLink from '../../components/AssistLink'
import Api from '../../util/Api'
import { getLinkPath, gotoLink } from '../../util/Env'
import { parseError } from '../../util/Helper'
import { GENERIC_ERROR_MESSAGE } from '../../util/constants'
import SkipLink from '../../components/SkipLink'

const PREFIX = 'Options'

const classes = {
  root: `${PREFIX}-root`,
  errorWhite: `${PREFIX}-errorWhite`,
  mfaTitle: `${PREFIX}-mfaTitle`,
  optionTitle: `${PREFIX}-optionTitle`,
  optionDetail: `${PREFIX}-optionDetail`,
  optionLevel: `${PREFIX}-optionLevel`,
  mfaIcon: `${PREFIX}-mfaIcon`,
  skipLink: `${PREFIX}-skipLink`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    maxWidth: '550px',
  },

  [`& .${classes.errorWhite}`]: {
    color: theme.palette.warning.contrastText,
  },

  [`& .${classes.mfaTitle}`]: {
    margin: '0.5rem 0 1rem',
  },

  [`& .${classes.optionTitle}`]: {
    color: theme.typography.body1.color,
  },

  [`& .${classes.optionDetail}`]: {
    color: theme.typography.subtitle1.color,
    fontSize: '1rem',
    paddingBottom: '1.5rem',
  },

  [`& .${classes.optionLevel}`]: {
    paddingLeft: '0.5rem',
    color: green[800],
    '&.mod-secure': {
      color: orange[800],
    },
  },

  [`& .${classes.mfaIcon}`]: {
    paddingRight: '0.5rem',
  },

}))

function Options() {
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const state = useLocation().state
  const notifications = useNotifications()

  const onSelect = async (type) => {
    errorMessage && setErrorMessage('')
    const logoLink = state && state.logo_link

    try {
      const res = await Api.post('/factor', { type })
      const { redirectLink, isExternalPage } = getLinkPath(res)

      gotoLink({
        navigate,
        link: redirectLink,
        state: {
          response: res,
          shouldNotRefetch: !isExternalPage,
          force_mfa: state && state.force_mfa,
          logo_link: logoLink,
        },
      })
    } catch (error) {
      if (error.isAxiosInterceptedAuthError) {
        gotoLink({
          // eslint-disable-next-line max-len
          navigate, data: error.data || error, notifications, isAuthnTarget: true, state: { logo_link: logoLink },
        })

        return
      }

      setErrorMessage(parseError(error) || GENERIC_ERROR_MESSAGE)
    }
  }

  return (
    <Root className={classes.root}>
      <Typography fontWeight={600} variant="h1" className={classes.mfaTitle}>
        Multi-factor Authentication
      </Typography>

      <Typography variant="body1">
        Enable multi-factor authentication (MFA) to add an extra layer of security to your account.
        When you log in, you’ll be required to enter your password along with a verification code.
      </Typography>

      {errorMessage && (
        <Box bgcolor="error.main" borderRadius={1} p={1} my={2}>
          <Typography className={classes.errorWhite} display="inline">
            {errorMessage}
          </Typography>
        </Box>
      )}

      <Box className={classes.options} mt={4}>
        <Box>
          <Box display="flex" alignItems="end" pb={3}>
            <VerifiedUserOutlined className={classes.mfaIcon} />
            <Typography variant="subtitle1" className={classes.optionTitle}>Authenticator App</Typography>
            <Typography variant="body2" className={classes.optionLevel}>
              (Secure)
            </Typography>
          </Box>
          <Typography variant="body1" className={classes.optionDetail}>
            Use a TOTP-compatible authenticator app to get verification codes.
          </Typography>
        </Box>
        <Button
          onClick={() => onSelect('totp')}
          fullWidth
          variant="contained"
          color="secondary"
          marginTop={1}
          large
        >
          Configure
        </Button>
        <Box mt={1.5} textAlign="center">
          <AssistLink onClick={() => onSelect('sms')} text="Configure SMS Text Message instead" />
        </Box>

        <Box mt={4} mb={1}>
          <Divider />
        </Box>
      </Box>

      {(!state || !state.force_mfa) && (
        <Box textAlign="right">
          <SkipLink errorMessage={errorMessage} setErrorMessage={setErrorMessage} text="Skip This Step" />
        </Box>
      )}
    </Root>
  )
}

export default Options
