import React from 'react'
import { styled } from '@mui/material/styles'
import MuiInputLabel from '@mui/material/InputLabel'
import PropTypes from 'prop-types'

const PREFIX = 'index'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledMuiInputLabel = styled(MuiInputLabel)(({ theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    marginBottom: theme.spacing(0.75),
  },
}))

export default function InputLabel({ children, id, required = false }) {
  return (
    <StyledMuiInputLabel classes={classes} id={id} required={required}>
      {children}
    </StyledMuiInputLabel>
  )
}

InputLabel.propTypes = {
  children: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
}
