import React from 'react'
import PropTypes from 'prop-types'
import { BaseInput, CommonInputDefaultProps } from '../BaseInput'

export default function TextField({
  autoComplete,
  autoFocus,
  disabled,
  endAdornment,
  error,
  fullWidth,
  gutterBottom,
  helperText,
  inputProps,
  InputProps,
  inputRef,
  label,
  labelRequired = false,
  large,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  startAdornment,
  type = 'text',
  value,
  readOnly = false,
  disabledEndAdornmentPointerEvents,
}) {
  return (
    <BaseInput
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled || readOnly}
      endAdornment={endAdornment}
      error={error}
      fullWidth={fullWidth}
      gutterBottom={gutterBottom}
      helperText={helperText}
      inputProps={inputProps}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={InputProps}
      inputRef={inputRef}
      label={label}
      labelRequired={labelRequired}
      large={large}
      name={name}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      startAdornment={startAdornment}
      type={type}
      value={value}
      disabledEndAdornmentPointerEvents={disabledEndAdornmentPointerEvents}
    />
  )
}

TextField.defaultProps = CommonInputDefaultProps

TextField.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  onBlur: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  endAdornment: PropTypes.node,
  large: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  startAdornment: PropTypes.node,
  /**
 * @ignore
 * Used only by the `AutoComplete` component
 */
  inputProps: PropTypes.shape({}),
  /**
   * @ignore
   * Used only by the `AutoComplete` component
   */
  InputProps: PropTypes.shape({}),
  /**
   * @ignore
   * Used only by the `DatePicker` component
   */
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
  /**
   * @ignore
   * Used only by the `Select` component
   */
  labelRequired: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  disabledEndAdornmentPointerEvents: PropTypes.bool,
}
