import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { SnackbarProvider } from 'notistack'

export const SnackbarContext = createContext()

export default function Snackbar({ children }) {
  const snackbarProps = {
    maxSnack: 4,
    dense: true,
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SnackbarProvider {...snackbarProps}>{children}</SnackbarProvider>
}

Snackbar.propTypes = {
  children: PropTypes.node.isRequired,
}
