import React from 'react'
import { styled } from '@mui/material/styles'
import MuiLink from '@mui/material/Link'
import PropTypes from 'prop-types'

const PREFIX = 'index'

const classes = {
  root: `${PREFIX}-root`,
  colorPrimary: `${PREFIX}-colorPrimary`,
}

const StyledMuiLink = styled(MuiLink)(({ theme }) => ({
  [`&.${classes.root}`]: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    cursor: 'pointer',
  },

  [`& .${classes.colorPrimary}`]: {
    color: theme.palette.secondary.dark,
  },
}))

export default function Link({
  children,
  color = 'primary',
  centered,
  component = 'a',
  newTab = false,
  onClick,
  href,
  underline = 'hover',
  tabIndex,
}) {
  return (
    <StyledMuiLink
      classes={classes}
      href={href}
      align={centered ? 'center' : 'inherit'}
      color={color}
      component={component}
      display={centered ? 'block' : 'initial'}
      onClick={onClick}
      rel={newTab ? 'noopener noreferrer' : undefined}
      target={newTab ? '_blank' : '_self'}
      TypographyClasses={classes}
      underline={underline}
      tabIndex={tabIndex}
    >
      {children}
    </StyledMuiLink>
  )
}

Link.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  color: PropTypes.oneOf(['primary', 'textPrimary', 'textSecondary']),
  component: PropTypes.elementType,
  newTab: PropTypes.bool,
  underline: PropTypes.oneOf(['none', 'hover', 'always']),
  tabIndex: PropTypes.number,
}
