import React from 'react'
import { Box, Typography as MuiTypography } from '@mui/material'
import PropTypes from 'prop-types'

export default function Typography({
  children,
  color,
  variant = 'body1',
  fontWeight = 400,
  gutterBottom = false,
  fontStyle = 'normal',
  className,
}) {
  return (
    <MuiTypography
      className={className}
      variant={variant}
      gutterBottom={gutterBottom}
      color={color}
    >
      <Box component="span" display="inline" fontStyle={fontStyle} fontWeight={fontWeight}>
        {children}
      </Box>
    </MuiTypography>
  )
}

Typography.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  color: PropTypes.oneOf(['error', 'initial', 'textPrimary', 'textSecondary']),
  fontStyle: PropTypes.oneOf(['bold', 'italic', 'normal']),
  fontWeight: PropTypes.oneOf([400, 500, 600]),
  gutterBottom: PropTypes.bool,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'inherit',
  ]),
  className: PropTypes.string,
}
