import PropTypes from 'prop-types'
import { GENERIC_ERROR_MESSAGE } from './constants'

export const parseError = ({
  // eslint-disable-next-line camelcase
  details = {}, description = '', message = '', title = '', status_code,
}) => {
  if (message) {
    return message
  }
  // eslint-disable-next-line camelcase
  if (status_code) {
    if (Array.isArray(details)) {
      return details.map((detail) => detail.description).join('. ')
    }
    if (Object.keys(details).length > 0) {
      return details[0].description
    }
  }
  if (description) {
    return description
  }
  if (title) {
    return title
  }

  return GENERIC_ERROR_MESSAGE
}

parseError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    status_code: PropTypes.number,
    details: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
    description: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
}
