/* eslint-disable max-len */
import { Box, Divider } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en.json'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import AssistLink from '../../components/AssistLink'
import Button from '../../components/Button'
import CountrySelect from '../../components/CountrySelect'
import FormikTextField from '../../components/FormikTextField'
import SkipLink from '../../components/SkipLink'
import Typography from '../../components/Typography'
import useNotifications from '../../hooks/useNotifications'
import Api from '../../util/Api'
import { getLinkPath, gotoDocs, gotoLink } from '../../util/Env'
import {
  ACCOUNT_LOCKED_MESSAGE,
  INVALID_PHONE_NUMBER_MESSAGE,
  MESSAGE_DATA_RATES_MESSAGE,
  UNABLE_TO_VERIFY_NUMBER_MESSAGE,
} from '../../util/constants'

const PREFIX = 'Challenge'

const classes = {
  skipBtn: `${PREFIX}-skipBtn`,
  errorWhite: `${PREFIX}-errorWhite`,
}

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.skipBtn}`]: {
    cursor: 'pointer',
    color: deepPurple[800],
  },

  [`& .${classes.errorWhite}`]: {
    color: theme.palette.warning.contrastText,
  },
}))

function Challenge() {
  const [country, setCountry] = useState('US')
  const [countryCode, setCountryCode] = useState('1')
  const [serverError, setServerError] = useState('')
  const [isExternalRedirect, setIsExternalRedirect] = useState(false)
  const navigate = useNavigate()
  const state = useLocation().state
  const notifications = useNotifications()
  const logoLink = state && state.logo_link

  const {
    values,
    dirty,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldError,
    isSubmitting,
  } = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: Yup.object({
      phone: Yup.string().test('phone-test', INVALID_PHONE_NUMBER_MESSAGE, (val) => isValidPhoneNumber(`+${country ? countryCode : ''}${val}`)),
    }),
    onSubmit: async (vals, { setSubmitting }) => {
      serverError && setServerError('')

      if (!isValidPhoneNumber(`+${country ? countryCode : ''}${vals.phone}`)) {
        setFieldError('phone', INVALID_PHONE_NUMBER_MESSAGE)

        return
      }

      setFieldError('phone', undefined)

      const val = `+${countryCode}${vals.phone.replace(/\D/g, '')}`

      try {
        const res = await Api.post('/factor/challenge', {
          phone_number: val,
        })

        if (!res || !res['@links']) {
          throw new Error(UNABLE_TO_VERIFY_NUMBER_MESSAGE)
        }

        // navigating to external page based on backend,
        // routing not set by UI router configuration,
        // so finally block is completed while url is changing
        // eslint-disable-next-line camelcase
        const { redirectLink, isExternalPage, help_link } = getLinkPath(res)

        setIsExternalRedirect(isExternalPage)

        gotoLink({
          navigate,
          link: redirectLink,
          isExternalPage,
          state: {
            response: res,
            shouldNotRefetch: !isExternalPage,
            force_mfa: state && state.force_mfa,
            help_link,
            logo_link: logoLink,
          },
        })
      } catch (err) {
        if (err.isAxiosInterceptedAuthError) {
          gotoLink({
            navigate, data: err.data || err, notifications, isAuthnTarget: true, state: { logo_link: logoLink },
          })
          setIsExternalRedirect(true)

          return
        }

        if (err.code === 'account_locked') {
          gotoLink({
            navigate,
            link: '/login',
            state: {
              error: ACCOUNT_LOCKED_MESSAGE,
              error_code: 'account_locked',
              logo_link: logoLink,
            },
          })
        } else {
          setServerError(UNABLE_TO_VERIFY_NUMBER_MESSAGE)
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  const onCountryChange = (event) => {
    const countryCodeWithNumber = `+${event.value ? getCountryCallingCode(event.value) : ''}${values.phone}`
    const isPhoneValid = isValidPhoneNumber(countryCodeWithNumber)

    setCountryCode(getCountryCallingCode(event.value).toString())
    setCountry(event.value)
    setFieldError('phone', !isPhoneValid ? INVALID_PHONE_NUMBER_MESSAGE : undefined)
  }

  return (
    <Root onSubmit={handleSubmit}>
      <Box mb={3}>
        <Typography variant="body1">
          Select your country and enter a phone number
          you would like to use for SMS verification.
        </Typography>
      </Box>

      {serverError && (
        <Box bgcolor="error.main" borderRadius={1} p={1} mb={2}>
          <Typography className={classes.errorWhite} display="inline">
            {serverError}
          </Typography>
        </Box>
      )}

      <CountrySelect
        labels={en}
        value={country}
        onChange={onCountryChange}
        name="country"
      />

      <Box mt={2}>
        <FormikTextField
          type="tel"
          placeholder="Phone Number..."
          name="phone"
          value={values.phone}
          onChange={handleChange}
          error={
            dirty
            && touched.phone
            && (!!errors.phone || !!errors.countryCode || !!errors.country)
          }
          helperText={
            dirty && touched.phone && (!!errors.phone || !!errors.countryCode || !!errors.country)
              ? errors.phone
              : MESSAGE_DATA_RATES_MESSAGE
          }
          fullWidth
          large
        />
      </Box>

      <Box mt={3} display="flex" justifyContent="center">
        <Button type="submit" fullWidth large variant="contained" disabled={isSubmitting || isExternalRedirect || !!errors.phone || !values.phone}>Send Code</Button>
      </Box>

      <Box mt={4} mb={1}>
        <Divider />
      </Box>

      <Box display="flex" justifyContent="space-between">
        {state && state.help_link && <AssistLink onClick={(e) => gotoDocs({ e, link: state.help_link })} text="Need Help?" href={state.help_link} />}

        {(!state || !state.force_mfa) && (
          <Box textAlign="right">
            <SkipLink errorMessage={serverError} setErrorMessage={setServerError} setIsExternalRedirect={setIsExternalRedirect} text="Skip For Now" />
          </Box>
        )}
      </Box>
    </Root>
  )
}

export default Challenge
