import axios from 'axios'
import Cookies from 'js-cookie'

const beforeLoginFormErrorCodes = ['no_sso_user', 'missing_sso_redirect_uri']

axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['content-type'] = 'application/json'

const Api = axios.create({
  baseURL: '/',
})

Api.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers['x-csrf-token'] = Cookies.get('idsrv.antiforgery')
  return config
}, (error) => Promise.reject(error.response ? error.response.data : error))

Api.interceptors.response.use((response) => {
  const res = response.data ? { ...response.data, enable_passwordless_login: true } : response
  return res
}, (error) => {
  if (error.response) {
    const { data, status } = error.response
    if (status === 409) {
      if (beforeLoginFormErrorCodes.includes(data.code)) {
        return Promise.reject(data)
      }

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ ...data, isAxiosInterceptedAuthError: true })
    }

    return Promise.reject(data)
  }

  return Promise.reject(error)
})

export default Api
