import React from 'react'
import { styled } from '@mui/material/styles'
import { ReactComponent as Icon } from '../../images/github.svg'

const PREFIX = 'GitHubIcon'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledIcon = styled(Icon)(() => ({
  [`&.${classes.root}`]: {
    height: 16,
    width: 16,
    '& path': {
      fill: 'currentColor',
    },
  },
}))

export default function GitHubIcon(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledIcon className={classes.root} {...props} data-testid="github-icon" />
}
