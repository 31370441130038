/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import {
  Route,
  Routes,
  useLocation, useNavigate,
} from 'react-router-dom'
import Layout from './components/Layout'
import LoadingPage from './components/LoadingPage'
import useNotifications from './hooks/useNotifications'
import Challenge from './routes/Challenge'
import Login from './routes/Login'
import Logout from './routes/Logout'
import Options from './routes/Options'
import Verify from './routes/Verify'
import Api from './util/Api'
import { PATHS, gotoLink } from './util/Env'
import { parseError } from './util/Helper'
import { ACCOUNT_LOCKED_MESSAGE, GENERIC_ERROR_MESSAGE } from './util/constants'

import './App.scss'

function App() {
  const [error, setError] = useState()
  const [isSpin, setIsSpin] = useState(false)
  const notifications = useNotifications()
  const location = useLocation()
  const navigate = useNavigate()

  const fetchInfo = async ({ controller = {} }) => {
    error && setError('')
    setIsSpin(true)

    try {
      const res = await Api.get('/authn/session', { signal: controller.signal })

      const hasLinks = res['@links'] && !Array.isArray(res['@links']) && typeof res['@links'] === 'object'
      // login_logo should always be present, but href will be null if not a partner logo
      const hasLoginLogo = hasLinks && res['@links'].login_logo && res['@links'].login_logo.href
      const social_logins = hasLinks
        // filter out links that are not social logins
        ? Object.entries(res['@links']).filter((el) => !['session', 'signup', 'help', 'change_password', 'login_logo', 'password_reset'].includes(el[0])).map((entry) => ({ [entry[0]]: entry[1] }))
        : []
      const signup_link = hasLinks && res['@links'].signup ? res['@links'].signup.href : null
      const help_link = hasLinks && res['@links'].help ? res['@links'].help.href : null
      const logo_link = hasLoginLogo ? res['@links'].login_logo.href : null
      // change_password_link is only present if the password is expired
      const change_password_link = res['@links']?.change_password?.href ?? null
      const password_reset_link = res['@links']?.password_reset?.href ?? '/password-reset'
      const support_email_address = res?.support_email_address

      if (res.state === 'change_password') {
        gotoLink({ navigate, link: change_password_link, isExternalPage: true })
      }

      if (res.state === 'verify_factor') {
        const factorResponse = await Api.get('/factor', { signal: controller.signal })

        gotoLink({
          navigate,
          link: PATHS[res.state],
          state: {
            response: factorResponse,
            force_mfa: res.force_mfa,
            social_logins,
            shouldNotRefetch: true,
            signup_link,
            help_link,
            logo_link,
            change_password_link,
            password_reset_link,
            support_email_address,
          },
        })
      } else {
        gotoLink({
          navigate,
          link: PATHS[res.state],
          state: {
            force_mfa: res.force_mfa,
            social_logins,
            signup_link,
            help_link,
            shouldNotRefetch: true,
            ...location.state,
            logo_link,
            change_password_link,
            password_reset_link,
            support_email_address,
          },
        })
      }
    } catch (err) {
      if (err.isAxiosInterceptedAuthError) {
        gotoLink({
          navigate, data: err.data || err, notifications, isAuthnTarget: true,
        })

        return
      }

      if (err.code === 'account_locked') {
        gotoLink({ navigate, link: '/login', state: { error: ACCOUNT_LOCKED_MESSAGE, error_code: 'account_locked' } })

        return
      }

      setError(parseError(err) || GENERIC_ERROR_MESSAGE)
    } finally {
      setIsSpin(false)
    }
  }

  useEffect(() => {
    const controller = new AbortController()

    // (1) fetchInfo initially called when navigate or url path changes
    // (2) shouldNotRefetch added to state on (1) to prevent second call
    if (!location.state || !location.state.shouldNotRefetch) {
      fetchInfo({ controller })
    }

    return () => controller.abort()
  }, [location.pathname, navigate])

  // edge-case based on https://github.com/remix-run/react-router/issues/8673#issuecomment-1047417811:
  // everytime user refreshes, and
  // current state has shouldNotRefetch, then
  // redirect user back to root
  // NOTE: does not call session a second time since useEffect hook above gets retriggered from deps
  // (typically if user clears cookies and refreshes)
  useEffect(() => {
    const controller = new AbortController()

    if (location.state && location.state.shouldNotRefetch) {
      gotoLink({ navigate, state: {} })
    }

    return () => controller.abort()
  }, [])

  if (isSpin) {
    return <LoadingPage />
  }

  return (
    <Layout error={error} logoLink={location.state && location.state.logo_link}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/temp" element={<Logout />} />
        <Route path="/options" element={<Options />} />
        <Route path="/challenge" element={<Challenge />} />
        <Route path="/verify" element={<Verify />} />
      </Routes>
    </Layout>
  )
}

export default App
