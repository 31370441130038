import { Box, Divider, IconButton, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import cx from 'classnames'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import AssistLink from '../../components/AssistLink'
import Button from '../../components/Button'
import FormikTextField from '../../components/FormikTextField'
import SignUpLink from '../../components/SignUpLink'
import SocialButton from '../../components/SocialButton'
import Typography from '../../components/Typography'
import useNotifications from '../../hooks/useNotifications'
import bottomRightTriangle from '../../images/bottom_right_triangle.png'
import { ReactComponent as EyeOff } from '../../images/eye-off-outline.svg'
import { ReactComponent as EyeOn } from '../../images/eye-on-outline.svg'
import topLeftTriangle from '../../images/top_left_triangle.png'
import Api from '../../util/Api'
import colors from '../../util/Colors'
import { getLinkPath, gotoDocs, gotoLink } from '../../util/Env'
import { parseError } from '../../util/Helper'
import {
  ACCOUNT_LOCKED_MESSAGE,
  FORCE_PASSWORD_RESET_MESSAGE,
  FORCE_PASSWORD_RESET_MESSAGE_NO_LINK,
  LOGIN_FIELDS_ERROR_MESSAGE,
} from '../../util/constants'

const PREFIX = 'Login'

const classes = {
  error: `${PREFIX}-error`,
  passwordField: `${PREFIX}-passwordField`,
  passwordSso: `${PREFIX}-passwordSso`,
  pwIcon: `${PREFIX}-pwIcon`,
  passwordSolo: `${PREFIX}-passwordSolo`,
  errorWhite: `${PREFIX}-errorWhite`,
  forgotBtn: `${PREFIX}-forgotBtn`,
  link: `${PREFIX}-link`,
  forcePwBox: `${PREFIX}-forcePwBox`,
  divider: `${PREFIX}-divider`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.error}`]: {
    maxWidth: '373px',
    lineHeight: '1.8rem',
  },

  [`& .${classes.passwordField}`]: {
    marginTop: '1.2rem',
  },

  [`& .${classes.passwordSso}`]: {
    marginBottom: '1.2rem',

    label: {
      cursor: 'pointer',
    },
  },

  [`& .${classes.pwIcon}`]: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '28px',
    width: '28px',
    textAlign: 'center',
    cursor: 'pointer',
    color: colors.GraniteGray,
  },

  [`& .${classes.passwordSolo}`]: {
    marginLeft: 'auto',
  },

  [`& .${classes.errorWhite}`]: {
    color: theme.palette.warning.contrastText,
  },

  [`& .${classes.forgotBtn}`]: {
    fontWeight: 500,
    lineHeight: theme.typography.caption.lineHeight,
    color: theme.typography.caption.color,
    cursor: 'pointer',
    fontSize: theme.typography.caption.fontSize,
  },

  [`& .${classes.link}`]: {
    cursor: 'pointer',
    color: theme.palette.secondary.dark,
  },

  [`& .${classes.forcePwBox}`]: {
    marginBottom: '1.5rem',
    borderRadius: 3,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#EBF8FD',
    '& a': {
      color: '#0092CB',
    },
    [`&.${classes.accountLockedErrorBox}`]: {
      backgroundColor: theme.palette.error.main,
      border: 'none',
    },
  },

  [`& .${classes.divider}`]: {
    fontSize: theme.typography.body2.fontSize,
  },
}))

const Triangles = () => (
  <>
    <Box component="img" position="absolute" left={0} top={0} src={topLeftTriangle} />
    <Box component="img" position="fixed" right={0} bottom={0} src={bottomRightTriangle} />
  </>
)

function Login() {
  const navigate = useNavigate()
  const state = useLocation().state
  const notifications = useNotifications()
  const largeScreen = useMediaQuery('(min-width: 1024px)')

  const socialLogins = state && state.social_logins ? state.social_logins : []
  const signupLink = state && state.signup_link ? state.signup_link : ''
  const helpLink = state && state.help_link ? state.help_link : null
  const passwordResetLink = state && state.password_reset_link ? state.password_reset_link : '/password-reset'
  const supportEmailAddress = state && state?.support_email_address ? state.support_email_address : null
  const [serverError, setServerError] = useState(state && state.error ? state.error : '')
  const [serverErrorCode, setServerErrorCode] = useState(
    state && state.error_code ? state.error_code : '',
  )
  const forcePasswordMessage = supportEmailAddress ? FORCE_PASSWORD_RESET_MESSAGE_NO_LINK : FORCE_PASSWORD_RESET_MESSAGE
  const [viewPwd, setViewPwd] = useState(false)
  const [isEmailOnlyView, setIsEmailOnlyView] = useState(true)
  const [alertMessage, setAlertMessage] = useState('')
  const [isForcedPasswordReset, setIsForcedPasswordReset] = useState(false)
  const [isExternalRedirect, setIsExternalRedirect] = useState(false)
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required('Required'),
      password: !isEmailOnlyView ? Yup.string().required('Required') : null,
    }),
    onSubmit: async (vals, { setSubmitting }) => {
      serverError && setServerError('')

      try {
        const res = await Api.post('/authn/session', {
          username: vals.username.trim(),
          password: vals.password.trim(),
        })

        if (!res || !res.state || !res['@links']) {
          throw new Error(
            'An error occurred while attempting to log you in. Please try again or contact support if the error persists.',
          )
        }

        const logoLink = res['@links']?.login_logo?.href ?? null
        if (!res.is_sso_user && res.state === 'user_authentication') {
          // step 1 is authentication, second is verfication
          setIsEmailOnlyView(false)

          return
        }

        if (res.state === 'user_authentication') {
          return
        }

        if (res.state === 'change_password') {
          gotoLink({ navigate, link: res['@links'].change_password.href, isExternalPage: true })

          return
        }

        // eslint-disable-next-line camelcase
        const { redirectLink, isExternalPage, help_link } = getLinkPath(res)
        // navigating to external page based on backend,
        // routing not set by UI router configuration,
        // so finally block is completed while url is changing
        if (res.state === 'verify_factor') {
          const factorResponse = await Api.get('/factor')
          gotoLink({
            navigate,
            link: redirectLink,
            isExternalPage,
            state: {
              response: { ...res, ...factorResponse },
              shouldNotRefetch: !isExternalPage,
              force_mfa: res.force_mfa || (state && state.force_mfa),
              help_link,
              logo_link: logoLink,
            },
          })
        } else {
          gotoLink({
            navigate,
            link: redirectLink,
            isExternalPage,
            state: {
              shouldNotRefetch: !isExternalPage,
              force_mfa: res.force_mfa || (state && state.force_mfa),
              help_link,
              logo_link: logoLink,
            },
          })
        }

        // setIsExternalRedirect(isExternalPage)
      } catch (err) {
        if (err.isAxiosInterceptedAuthError) {
          if (err.code === 'force_password_reset') {
            setAlertMessage(forcePasswordMessage)
            setIsForcedPasswordReset(true)
          } else {
            gotoLink({
              navigate,
              data: err.data || err,
              notifications,
              isAuthnTarget: true,
            })
            setIsExternalRedirect(true)
          }

          return
        }

        if (err.code === 'account_locked') {
          setServerErrorCode('account_locked')
          setServerError(ACCOUNT_LOCKED_MESSAGE)

          return
        }

        setServerError(LOGIN_FIELDS_ERROR_MESSAGE(isEmailOnlyView))
      } finally {
        setSubmitting(false)
      }
    },
  })
  const isAccountLocked = serverErrorCode === 'account_locked'

  const updateLoginView = async () => {
    setServerError('')
    try {
      await Api.delete('/authn/session')
      values.username && setFieldValue('username', '')
      setFieldTouched('username', false)
      values.password && setFieldValue('password', '')
      setFieldTouched('password', false)
      setIsEmailOnlyView(!isEmailOnlyView)
    } catch (err) {
      setServerError(parseError(err))
    }
  }

  const toggleViewPassword = () => {
    setViewPwd((prev) => !prev)
  }
  const handleResetPassword = () => {
    gotoLink({ navigate, link: passwordResetLink, isExternalPage: true })
  }

  return (
    <StyledBox>
      <Typography fontWeight={600} variant="h1">
        Sign In To Your Account
      </Typography>

      {largeScreen && <Triangles />}

      {serverError && !isAccountLocked && (
        <Box bgcolor="error.main" borderRadius={1} p={1} mb={2}>
          <Typography className={classes.errorWhite} display="inline">
            {serverError}
          </Typography>
        </Box>
      )}

      {isForcedPasswordReset || isAccountLocked ? (
        <>
          <Box
            className={cx(classes.forcePwBox, { [classes.accountLockedErrorBox]: isAccountLocked })}
            borderRadius={1}
            p={2}
            mb={3}
          >
            <Typography
              variant="h6"
              className={cx({ [classes.errorWhite]: isAccountLocked })}
              data-testid="message-alert"
            >
              {serverError || alertMessage}
            </Typography>
          </Box>
          <Box mt={3} mb={1.5}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              large
              onClick={(e) => gotoLink({ e, link: passwordResetLink, isExternalPage: true })}
            >
              Reset Password
            </Button>
          </Box>
          <Box textAlign="center">
            <AssistLink
              onClick={() => gotoLink({ navigate, isRoot: true, isAuthnTarget: false })}
              text="Back to Login"
            />
          </Box>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box mb={isEmailOnlyView ? 0 : 1}>
            <FormikTextField
              endAdornment={
                !isEmailOnlyView ? (
                  <AssistLink onClick={updateLoginView} text="Change" />
                ) : undefined
              }
              fullWidth
              placeholder="Username"
              name="username"
              autoFocus={isEmailOnlyView}
              type="text"
              gutterBottom
              large
              variant="outlined"
              error={touched.username && !!errors.username}
              helperText={touched.username && !!errors.username ? errors.username : ''}
              onChange={handleChange}
              value={values.username}
              readOnly={!isEmailOnlyView}
              hideErrors
              disabledEndAdornmentPointerEvents={isEmailOnlyView}
              autoComplete="username"
            />
          </Box>

          {!isEmailOnlyView && (
            <Box my={1}>
              <FormikTextField
                fullWidth
                endAdornment={(
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleViewPassword}
                    size="small"
                    className={classes.pwIcon}
                    edge="end"
                  >
                    {viewPwd ? <EyeOff /> : <EyeOn />}
                  </IconButton>
                )}
                name="password"
                placeholder="Password"
                type={viewPwd ? 'text' : 'password'}
                autoComplete="current-password"
                gutterBottom
                large
                disabledEndAdornmentPointerEvents={false}
                error={touched.password && !!errors.password}
                helperText={touched.password && !!errors.password ? errors.password : ''}
                onChange={handleChange}
                value={values.password}
                autoFocus={!isEmailOnlyView}
                hideErrors
              />
            </Box>
          )}

          {!isEmailOnlyView && (
            <Box mb={3}>
              <AssistLink
                onClick={handleResetPassword}
                text="Forgot password?"
                href={passwordResetLink}
                color="textSecondary"
              />
            </Box>
          )}

          <Box mt={1} mb={1.5}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting || isExternalRedirect}
              color="secondary"
              large
            >
              {isEmailOnlyView ? 'Next' : 'Login'}
            </Button>
          </Box>
        </form>
      )}

      {helpLink && !supportEmailAddress && (
        <AssistLink
          onClick={(e) => gotoDocs({ e, link: helpLink })}
          text="Need Help?"
          href={helpLink}
        />
      )}

      {supportEmailAddress && (
        <AssistLink
          onClick={(e) => gotoDocs({ e, link: `mailto:${supportEmailAddress}` })}
          text="Need Help?"
          href={`mailto:${supportEmailAddress}`}
        />
      )}

      {socialLogins.length > 0 && (
        <>
          <Box my={4}>
            <Divider className={classes.divider}>or</Divider>
          </Box>
          <Box>
            {socialLogins.map((method, index) => {
              const socialKey = Object.keys(method)[0]

              return (
                <Box mb={2} key={index}>
                  <SocialButton
                    label={`Sign in with ${
                      socialKey.slice(0, 1).toUpperCase() + socialKey.slice(1)
                    }`}
                    type={`${socialKey}`}
                    href={method[socialKey].href}
                  />
                </Box>
              )
            })}
          </Box>
        </>
      )}

      {(signupLink && !supportEmailAddress) && (
        <Box textAlign="center">
          <SignUpLink link={signupLink} />
        </Box>
      )}
    </StyledBox>
  )
}

export default Login
