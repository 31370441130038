import React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import App from './App'
import ThemeProvider from './providers/ThemeProvider'
import Snackbar from './components/Snackbar'
import * as serviceWorker from './serviceWorker'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <HashRouter>
      <ThemeProvider>
        <Snackbar>
          <CssBaseline />
          <App />
        </Snackbar>
      </ThemeProvider>
    </HashRouter>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
