import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Typography from '../../components/Typography'
import useNotifications from '../../hooks/useNotifications'
import Api from '../../util/Api'
import { gotoLink } from '../../util/Env'
import { parseError } from '../../util/Helper'

const PREFIX = 'Logout'

const classes = {
  errorWhite: `${PREFIX}-errorWhite`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.errorWhite}`]: {
    color: theme.palette.warning.contrastText,
  },
}))

function Logout() {
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const notifications = useNotifications()

  const onLogout = async () => {
    error && setError('')

    try {
      await Api.get('/logout')
      navigate('/')
    } catch (err) {
      if (err.isAxiosInterceptedAuthError) {
        gotoLink({
          navigate, data: err.data || err, notifications, isAuthnTarget: true,
        })

        return
      }

      setError(parseError(err))
    }
  }

  return (
    <StyledBox>
      {error && (
        <Box bgcolor="error.main" borderRadius={1} p={1} mb={2}>
          <Typography className={classes.errorWhite} display="inline">
            {error}
          </Typography>
        </Box>
      )}
      <p>User logged in</p>
      <button onClick={onLogout}>Log Out</button>
    </StyledBox>
  )
}

export default Logout
