import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import useNotifications from '../../hooks/useNotifications'
import Api from '../../util/Api'
import { GENERIC_ERROR_MESSAGE, SKIP_STEP_ERROR_MESSAGE } from '../../util/constants'
import { getLinkPath, gotoLink } from '../../util/Env'
import { parseError } from '../../util/Helper'
import AssistLink from '../AssistLink'

const SkipLink = ({
  state, errorMessage = '', setErrorMessage, text, setIsExternalRedirect,
}) => {
  const notifications = useNotifications()
  const navigate = useNavigate()

  const onSkip = async (e) => {
    e.preventDefault()
    errorMessage && setErrorMessage('')

    try {
      const res = await Api.patch('/authn/session', { skip_mfa_setup: 'true' })

      if (!res || !res['@links']) {
        throw new Error(SKIP_STEP_ERROR_MESSAGE)
      }

      // navigating to external page based on backend,
      // routing not set by UI router configuration,
      // so finally block is completed while url is changing
      // ROOT REDIRECT: should take you to root page (dashboard)
      // eslint-disable-next-line camelcase
      const { redirectLink, isExternalPage, help_link } = getLinkPath(res)

      gotoLink({
        navigate,
        link: redirectLink,
        isExternalPage,
        state: {
          shouldNotRefetch: !isExternalPage,
          force_mfa: state && state.force_mfa,
          help_link,
        },
      })

      setIsExternalRedirect && setIsExternalRedirect(true)
    } catch (error) {
      if (error.isAxiosInterceptedAuthError) {
        gotoLink({
          navigate, data: error.data || error, notifications, isAuthnTarget: true,
        })

        return
      }

      setErrorMessage(parseError(error) || GENERIC_ERROR_MESSAGE)
    }
  }

  return <AssistLink onClick={onSkip} text={text} />
}

SkipLink.propTypes = {
  text: PropTypes.string.isRequired,
  state: PropTypes.shape({
    force_mfa: PropTypes.bool,
  }),
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
  setIsExternalRedirect: PropTypes.func,
}

export default SkipLink
