import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import cx from 'classnames'

const PREFIX = 'index'

const classes = {
  root: `${PREFIX}-root`,
  fullscreen: `${PREFIX}-fullscreen`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },

  [`&.${classes.fullscreen}`]: {
    position: 'absolute',
    top: 72,
    left: theme.spacing(-1),
    height: 'calc(100vh - 128px)',
    width: `calc(100% + ${theme.spacing(1)})`,
    background: theme.palette.background.default,
    '.tabpanels &': {
      top: 0,
      height: 'calc(100vh - 275px)',
    },
  },
}))

export default function Loading({ fullscreen = true }) {
  return (
    <Root className={cx({ [classes.root]: true, [classes.fullscreen]: fullscreen })} data-testid="loading-spinner">
      <CircularProgress data-testid="loading" />
    </Root>
  )
}

Loading.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    fullscreen: PropTypes.string,
  }),
  fullscreen: PropTypes.bool,
}
