import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

const PREFIX = 'Button'

const classes = {
  root: `${PREFIX}-root`,
  contained: `${PREFIX}-contained`,
  containedSecondary: `${PREFIX}-containedSecondary`,
  outlined: `${PREFIX}-outlined`,
  outlinedSecondary: `${PREFIX}-outlinedSecondary`,
  sizeLarge: `${PREFIX}-sizeLarge`,
  disabled: `${PREFIX}-disabled`,
  outlinedPrimary: `${PREFIX}-outlinedPrimary`,
  outlinedDual: `${PREFIX}-outlinedDual`,
  social: `${PREFIX}-social`,
}

const StyledMuiButton = styled(MuiButton)(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '1.6875rem',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  },

  [`&.${classes.contained}`]: {
    background: 'linear-gradient(89.92deg, #00BDA6 0%, #00A2E2 132.46%)',
    '&.Mui-disabled': {
      background: theme.palette.action.disabled,
      color: theme.palette.common.white,
    },
    '&:hover': {
      background: 'linear-gradient(89.77deg, #00A2E2 -6.72%, #01B18D 138.89%)',
    },
  },

  [`&.${classes.containedSecondary}`]: {
    background: 'linear-gradient(89.86deg, #812991 -1.31%, #00A2E2 138.3%)',
    '&.Mui-disabled': {
      background: theme.palette.action.disabled,
      color: theme.palette.common.white,
    },
    '&:hover': {
      background: 'linear-gradient(89.86deg, #812991 -1.31%, #00A2E2 138.3%)',
    },
  },

  [`&.${classes.outlined}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : theme.palette.common.white,
    borderColor: theme.palette.mode === 'dark' ? 'white' : '#01B18D',
    color: theme.palette.mode === 'dark' ? 'white' : '#01B18D',
    '&.Mui-sizeLarge': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:not(.Mui-outlinedPrimary):not(.Mui-disabled)': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    },
    '&.Mui-disabled': {
      // borderColor: 'currentColor',
      border:
        theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.action.disabledBackground}`
          : '1px solid #C4C4C4',
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.action.disabledBackground
          : 'linear-gradient(0deg, rgba(1, 177, 141, 0.05), rgba(1, 177, 141, 0.05)), #FFFFFF',
    },
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.action.disabledBackground : 'red',
      border: theme.palette.mode === 'dark' ? '1px solid white' : '1px solid #01B18D',
    },
  },

  [`&.${classes.outlinedSecondary}`]: {
    borderColor: theme.palette.mode === 'dark' ? '#01B18D' : '#00A2E2',
    color: '#01B18D',
    '&:not(.Mui-outlinedPrimary):not(.Mui-disabled)': {
      color: theme.palette.mode === 'dark' ? '#01B18D' : '#00A2E2',
    },
    '&:hover': {
      borderColor: theme.palette.mode === 'dark' ? '#01B18D' : '#00A2E2',
    },
  },

  [`&.${classes.sizeLarge}`]: {
    borderRadius: 4,
    fontSize: theme.typography.button.fontSize,
    height: '2.375rem',
  },
  [`&.${classes.outlinedDual}`]: {
    borderColor: theme.palette.text.primary,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark' ? theme.palette.action.disabledBackground : '#F3F3F3',
      borderColor: theme.palette.text.primary,
    },
  },

  [`&.${classes.disabled}`]: {},
  [`&.${classes.outlinedPrimary}`]: {},
  [`&.${classes.social}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : theme.palette.common.white,
    borderColor: theme.palette.mode === 'dark' ? 'white' : theme.palette.common.black,
    border: '1px solid',
    color: theme.palette.mode === 'dark' ? 'white' : '#01B18D',
    '&.Mui-sizeLarge': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:not(.Mui-outlinedPrimary):not(.Mui-disabled)': {
      color:
        theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    },
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? theme.palette.action.disabledBackground
          : 'rgba(128, 128, 128, 0.2)',
    },
    '&.MuiButton-root.Button-social': {
      justifyContent: 'flex-start',
      '@media (max-width:450px)': {
        paddingLeft: '25%',
      },
      paddingLeft: '31%',
      display: 'flex',
    },
  },
}))

const Button = forwardRef(
  (
    {
      captchaProps,
      children,
      color = 'primary',
      disabled = false,
      endIcon,
      forceOutlined = false,
      fullWidth = false,
      href,
      large,
      onClick,
      startIcon,
      type = 'button',
      variant = 'outlined',
    },
    ref,
  ) => (
    <StyledMuiButton
      classes={classes}
      // @ts-ignore
      color={color}
      disabled={disabled}
      endIcon={endIcon}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      ref={ref}
      size={large ? 'large' : 'medium'}
      startIcon={startIcon}
      type={type}
      variant={forceOutlined ? 'outlined' : variant}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...captchaProps}
    >
      {children}
    </StyledMuiButton>
  ),
)

Button.propTypes = {
  captchaProps: PropTypes.shape({
    className: PropTypes.string.isRequired,
    'data-sitekey': PropTypes.string.isRequired,
    'data-action': PropTypes.string.isRequired,
    'data-callback': PropTypes.string.isRequired,
  }),
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary', 'default', 'secondary', 'dual']),
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  forceOutlined: PropTypes.bool,
  fullWidth: PropTypes.bool,
  /**
   * @ignore
   * Used only by the `Link` component
   */
  href: PropTypes.string,
  large: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['outlined', 'contained']),
}

export default Button
