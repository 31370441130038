import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useNotifications from '../../hooks/useNotifications'
import Typography from '../Typography'
import FormikTextField from '../FormikTextField'
import Button from '../Button'
import AssistLink from '../AssistLink'
import Api from '../../util/Api'
import { gotoLink } from '../../util/Env'
import { parseError } from '../../util/Helper'
import { ACCOUNT_LOCKED_MESSAGE, VERIFICATION_CODE_FAILED_MESSAGE } from '../../util/constants'
import SkipLink from '../SkipLink'

const PREFIX = 'VerifyForm'

const classes = {
  skipBtn: `${PREFIX}-skipBtn`,
  errorWhite: `${PREFIX}-errorWhite`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.errorWhite}`]: {
    color: theme.palette.warning.contrastText,
  },
}))

const VerifyForm = ({
  type,
  desc,
  help,
  isSkippable,
  autoFocus,
  autoComplete,
  placeholder,
  hasDivider,
  helpLink,
  logoLink,
  onClickHelpLink,
}) => {
  const navigate = useNavigate()
  const notifications = useNotifications()
  const [serverError, setServerError] = useState('')
  const [isExternalRedirect, setIsExternalRedirect] = useState(false)

  const {
    values,
    dirty,
    touched,
    errors,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required('Required'),
    }),
    onSubmit: async (vals, { setSubmitting }) => {
      serverError && setServerError('')

      try {
        const res = await Api.post('/factor/verify', {
          code: vals.code,
        })

        if (!res || !res['@links']) {
          throw new Error(VERIFICATION_CODE_FAILED_MESSAGE)
        }

        if (res['@links'].redirect_uri) {
          // navigating to external page based on backend,
          // routing not set by UI router configuration,
          // so finally block is completed while url is changing
          gotoLink({ navigate, link: res['@links'].redirect_uri.href, isExternalPage: true })
          setIsExternalRedirect(true)
        } else {
          notifications.success('Verification successful!')
          gotoLink({ navigate })
        }
      } catch (err) {
        if (err.isAxiosInterceptedAuthError) {
          gotoLink({
            navigate, data: err.data || err, notifications, isAuthnTarget: true,
          })
          setIsExternalRedirect(true)

          return
        }

        if (err.code === 'account_locked') {
          gotoLink({ navigate, link: '/login', state: { error: ACCOUNT_LOCKED_MESSAGE, error_code: 'account_locked' } })
        } else {
          setServerError(VERIFICATION_CODE_FAILED_MESSAGE)
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  const onResend = async () => {
    serverError && setServerError('')

    try {
      await Api.post('/factor/resend')
    } catch (err) {
      if (err.isAxiosInterceptedAuthError) {
        gotoLink({
          navigate, data: err.data || err, notifications, isAuthnTarget: true,
        })

        return
      }

      const parsedError = parseError(err)
      if (err.code === 'account_locked') {
        gotoLink({ navigate, link: '/login', state: { error: ACCOUNT_LOCKED_MESSAGE, error_code: 'account_locked' } })
      } else {
        setServerError(parsedError || 'An error occurred resending the verification code.')
      }
    }
  }

  return (
    <Root>
      <Box mb={1.5}>
        <Typography variant="h4">
          Verification Code
        </Typography>
      </Box>
      <Box mb={type === 'sms' ? 1 : 2}>
        <Typography variant="subtitle2">
          {desc}
        </Typography>
      </Box>

      {serverError && (
        <Box bgcolor="error.main" borderRadius={1} p={1} mb={2}>
          <Typography className={classes.errorWhite} display="inline" data-testid="error-text">
            {serverError}
          </Typography>
        </Box>
      )}

      <Box mt={2}>
        <form onSubmit={handleSubmit}>
          <FormikTextField
            fullWidth
            large
            placeholder={placeholder}
            name="code"
            value={values.code}
            onChange={handleChange}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            error={dirty && touched.code && !!errors.code}
            helperText={
              dirty && touched.code && !!errors.code
                ? errors.code
                : help
            }
          />

          <Box mt={3} display="flex" justifyContent="center">
            <Button type="submit" fullWidth large variant="contained" disabled={isSubmitting || isExternalRedirect || !!errors.code || !values.code}>Verify</Button>
          </Box>

          {type === 'sms' && (
            <Box mt={1.5} textAlign="center">
              <AssistLink onClick={onResend} text="Resend Code" />
            </Box>
          )}

          {hasDivider && (
            <Box mt={4} mb={1}>
              <Divider />
            </Box>
          )}

          <Box display="flex" justifyContent="space-between">
            {helpLink && (
              <AssistLink onClick={onClickHelpLink} text="Need Help?" href={helpLink} />
            )}

            {isSkippable && (
              <Box textAlign="right">
                <SkipLink errorMessage={serverError} setErrorMessage={setServerError} text="Skip For Now" />
              </Box>
            )}
          </Box>
        </form>
      </Box>
    </Root>
  )
}

VerifyForm.propTypes = {
  type: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  help: PropTypes.string,
  isSkippable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helpLink: PropTypes.string,
  onClickHelpLink: PropTypes.func,
  hasDivider: PropTypes.bool,
  logoLink: PropTypes.string,
}

export default VerifyForm
