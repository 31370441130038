import React from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import Flags from 'country-flag-icons/react/3x2'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input'
import { useMediaQuery } from '@mui/material'

import './index.scss'

const CountrySelect = ({
  onChange, onBlur, labels, name,
}) => {
  const minScreen = useMediaQuery('(min-width: 450px)')

  const options = getCountries().map(((country) => (
    {
      label: labels[country],
      value: country,
      icon: country,
    }
  )))

  const filterOption = (option, inputValue) => {
    const inputValueLowerCased = inputValue.toString().toLowerCase()

    return (`+${getCountryCallingCode(option.value)}`.toLowerCase().includes(inputValueLowerCased)
      || option.label.toString().toLowerCase().includes(inputValueLowerCased)
      || option.value.toString().toLowerCase().includes(inputValueLowerCased))
  }

  const OptionComponent = (props = {}) => {
    // eslint-disable-next-line react/prop-types
    const { label, value, icon } = props
    const countryCallingCode = getCountryCallingCode(value)
    const Flag = Flags[icon]

    return (
      <div className="options" title={`${countryCallingCode}-${value}-${label}`}>
        <div className="option">
          <Flag title={label} className="flag" />
          <span className="option-label">{label}</span>
          {minScreen && (
            <span className="option-value">
              (
              {value}
              )
            </span>
          )}
        </div>
        <span className="option-calling_code">
          +
          {countryCallingCode}
        </span>
      </div>
    )
  }

  const SingleValueComponent = (props = {}) => {
    // eslint-disable-next-line react/prop-types
    if (!props.data) {
      return null
    }

    // eslint-disable-next-line react/prop-types
    const { label, value, icon } = props.data
    const countryCallingCode = getCountryCallingCode(value)
    const Flag = Flags[icon]

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.SingleValue className="country-select__single-value" {...props}>
        <div className="options">
          <Flag title={label} className="flag" />
          <span className="option-label">{label}</span>
          {minScreen && (
            <span className="option-value">
              (
              {value}
              )
            </span>
          )}
        </div>
        <span className="option-calling_code">
          +
          {countryCallingCode}
        </span>
      </components.SingleValue>
    )
  }

  const InputComponent = (props = {}) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Input {...props} className="country-select__input" data-testid="input-component" />
  )

  return (
    <Select
      classNamePrefix="select"
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      formatOptionLabel={OptionComponent}
      filterOption={filterOption}
      className="select-component"
      components={{ SingleValue: SingleValueComponent, Input: InputComponent }}
      placeholder="Country..."
      noOptionsMessage={() => 'No countries found...'}
      defaultValue={options.find(({ value }) => value === 'US')}
      blurInputOnSelect
      name={name}
    />
  )
}

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
  name: PropTypes.string,
}

export default CountrySelect
