import React from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
import createTheme from '../util/theme'

export default function ThemeProvider({ children }) {
  const theme = createTheme()

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
